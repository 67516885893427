<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.products')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <CInput :label="$t('common.name')"
                                        v-model="searchProductName" required horizontal v-on:keyup.enter="onSearch">
                                </CInput>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('common.published') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="searchPublished">
                                            <option :value="null">{{ $t('common.all') }}</option>
                                            <option :value="true">{{ $t('common.published') }}</option>
                                            <option :value="false">{{ $t('common.unPublished') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('pages.product.categoryParent') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="searchCategoryId">
                                            <option :value="null">{{ $t('pages.product.selectCategory') }}</option>
                                            <option v-for="(item, idx) in allCategories" v-bind:key="item.id" v-bind:value="item.id" v-bind:selected="idx == 0">
                                                {{item.hierarchyName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('common.markedAsHot') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="searchMarkedAsHot">
                                            <option :value="null">{{ $t('common.all') }}</option>
                                            <option :value="true">{{ $t('common.marked') }}</option>
                                            <option :value="false">{{ $t('common.notMarked') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('common.searchIncludeSubCategories') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <CSwitch :key="searchIncludeSubCategories"
                                                 color="info"
                                                 :checked.sync="searchIncludeSubCategories"
                                                 size="sm" />
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('common.markedAsNew') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="searchMarkedAsNew">
                                            <option :value="null">{{ $t('common.all') }}</option>
                                            <option :value="true">{{ $t('common.marked') }}</option>
                                            <option :value="false">{{ $t('common.notMarked') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>

                 <div v-if="allProducts != null && allProducts.totalPages > 0">
    Trang hiện tại: {{page}}
    <CPagination
    :activePage.sync="page"
      @update:activePage="onPageChange"
      :pages="allProducts.totalPages"
      size="sm"
      align="center"
    />
  </div>

                    <CDataTable :items="allProducts.data"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoadingProducts"
                                :items-per-page-select="{
                                label : 'Số lượng',
                                values : [10,20,50,100]
                                }"
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                pagination>
                        <template #footer v-if="allProducts != null && allProducts.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="10">
                                        <span>Tổng số</span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allProducts == null ? 0: allProducts.length}}</span><span style="padding-left:5px">sản phẩm</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>

                        <template #defaultPictureModel="{item}">
                            <td>
                                <img :src="item.defaultPictureModel.thumbImageUrl" width="50" height="50" v-if="item.defaultPictureModel != null && item.defaultPictureModel.thumbImageUrl" />
                                <img :src="'/content/images/no-image.gif'" width="50" height="50" v-else>
                            </td>
                        </template>
                        <template #status="{item}">
                            <td>
                                <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
                            </td>
                        </template>
                        <template #product_wholesalePrice="{item}">
                            <td>
                                <label v-text="$func.formatCurrency(item.wholesalePrice)" />
                            </td>
                        </template>
                        <template #showOnHomePage="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.showOnHomepage" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>
                        <template #markedAsNew="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.markedAsNew" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>
                        <template #published="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.published" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>

                        <template #user_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEdit(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDelete(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>
<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import { mapGetters, mapState, mapActions } from 'vuex'
    import ListForm from '@/components/ListForm.vue'
import { product } from '../../store/product.module'

	export default {
		name: 'ProductList',
		data() {            
            return {
                page: 1,
                searchProductName: '',
                searchCategoryId: null,
                searchShowOnHomepage: null,
                searchMarkedAsNew: null,
                searchMarkedAsHot: null,
                searchPublished: null,
                searchIncludeSubCategories: false,

				itemsPerPage: 10,
                warningModal: false,
                warningModalMessage: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod",
                fields: [
                    { key: 'defaultPictureModel', label: '' },
                    { key: 'name', label: i18n.t('common.name') },
                    { key: 'product_wholesalePrice', label: i18n.t('pages.product.wholesalePrice') },
                    { key: 'packagingSpecification', label: i18n.t('pages.product.packagingSpecification') },
                    { key: 'showOnHomePage', label: i18n.t('common.showOnHomepage') },
                    { key: 'markedAsNew', label: i18n.t('common.markedAsNew') },
                    { key: 'published', label: i18n.t('common.published') },

                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
		},
        components: {
            Confirmation, ListForm
        },
        computed: {
            ...mapState('category', ['allCategories']),
            ...mapGetters('product', ['isLoadingProducts', 'allProducts']),            
        },
        methods: {
            ...mapActions('category', ['getAllCategories']),
			getBadge(status) {
                return status === 'Active' ? 'success'
                    : status === 'Inactive' ? 'secondary'
                        : status === 'Pending' ? 'warning'
                            : status === 'Banned' ? 'danger' : 'primary'
            },
            
            async onDelete(item) {
                this.$refs.confirmation.show(i18n.t('pages.product.confirmDelete'), async () => {//
                    console.log('Delete Product', item);
                    var retVal = await this.$store.dispatch("product/deleteProduct", item.id);
                    if (retVal == true) {
                        this.$store.dispatch("product/getAllProducts");
                    }
                });
            },
            onEdit(item) {
                this.$router.push('/products/edit/' + item.id);
            },

            onSearch() {
                console.log('start search products');
                this.searchProducts(1);
            },
            searchProducts(searchPageNo) {
                var searchCriteria = {
                    searchProductName: this.searchProductName,
                    searchCategoryId: this.searchCategoryId,
                    searchShowOnHomepage: this.searchShowOnHomepage,
                    searchMarkedAsHot: this.searchMarkedAsHot,
                    searchMarkedAsNew: this.searchMarkedAsNew,
                    searchPublished: this.searchPublished,
                    searchIncludeSubCategories: this.searchIncludeSubCategories,
                    pagingModel: {
                        pageIndex: (searchPageNo != undefined && searchPageNo > 0) ? searchPageNo - 1 : 1,
                        pageSize: (this.itemsPerPage != undefined && this.itemsPerPage > 0) ? this.itemsPerPage : 10,
                    }
                }
                this.$store.dispatch("product/getAllProducts", searchCriteria)
            },

            onItemsPerPageChange(item) {
                this.itemsPerPage = item;
                localStorage.setItem('productItemsPerPage', item)

                if(this.page > 0){
                    this.searchProducts(this.page)
                }
            },

            onPageChange(newPageNumber){
            this.searchProducts(newPageNumber)
            }

		},
        mounted() {
            let productItemsPerPage = parseInt(localStorage.getItem('productItemsPerPage'));
            if (isNaN(productItemsPerPage))
                productItemsPerPage = 10;
            this.itemsPerPage = productItemsPerPage;

            this.getAllCategories();
            this.searchProducts(1);
        }
	}
</script>